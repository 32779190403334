@import "../../variables";

.header{
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
.logo{
  max-height: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 24px;
  color: $buttonColor;
  img{
    max-width: 250px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}