@import "../../variables";

.container{
  margin: 0 10px;
  padding-bottom: 120px;
  word-wrap: break-word;
}
.avatar{
  margin: 16px 0;
}
.name{
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
}
.info{
  font-size: 16px;
  a{
    color: $white;
    text-decoration: none;
  }
  hr{
    border-color: $buttonColor;
    border-bottom: 1px;
    margin: 30px 0;
    opacity: 0.6;
  }
  ul{
    padding-left: 25px;
    line-height: 150%;
    li::marker{
      color: $buttonColor
    }
  }
  .blogTheme {
    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 10px 30px
    }
  }
  .items{
    border-top: 1px solid $gray;
    padding-top: 18px;
    &:last-child{
      margin-bottom: 16px;
      border-bottom: 1px solid $gray;
    }
    .item{
      font-weight: 400;
      margin-bottom: 20px;
      &.category{
        font-weight: 600;
      }
      &.child{
        margin-top: -10px;
        font-size: 14px;
        color: $gray;
        svg{
          width: 16px;
          min-width: 16px;
          margin-left: 4px;
          margin-right: 14px;
        }
      }
    }

  }
  .item{
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .dotsItems{
    margin-top: 25px;
    margin-bottom: 35px;
    .item{
      &::after {
        content: '';
        order: 0;
        transform: translateY(-100%);
        width: 100%;
        height: 1px;
        background-image: radial-gradient(circle, $buttonColor 1px, transparent 1px);
        opacity: 0.8;
        background-position: 0 0;
        background-size: 8px 1px;
        margin: auto 10px 0;
      }

      span {
        white-space: nowrap;

        &:last-child {
          order: 1;
          font-weight: 600;
        }

        &:first-child {
          //color: $buttonColor;
          font-weight: 600;
        }
      }

      &:last-child {
        margin-bottom: 18px;
      }
    }
  }

  .text{
    margin-left: 35px;
  }
  svg{
    margin-right: 10px;
    width: 16px;
    height: 16px;
    min-width: 16px;
    &:not([aria-label*="social"]) path{
      stroke: $buttonColor;
    }
  }
}
.back{
  margin: 40px;
}
.backLink{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  text-decoration: none;
  margin: 0 auto;
  font-weight: 500;
  text-shadow: 1px 1px 10px #000000b8, 0 0 15px #000000b8, 0 0 2px #00000099;
  border-radius: 10px;
  color: $white;
  padding: 10px 10px 0;
  display: block;
  svg{
    margin-right: 10px;
    path{
      stroke: $white;
    }
  }
}
.socials{
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 8px;
}
.title{
  font-size: 14px;
  color: $gray;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  &+div{
    margin-bottom: 16px;
    white-space: pre-wrap;
  }
}