.pinInputContainer {
  display: flex;
  gap: 12px;
  justify-content: center;
}
.pinInput {
  width: 45px;
  height: 55px;
  font-size: 20px!important;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
}