@import "../../variables";
.filters{
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  gap: 10px;
  position: sticky;
  top: 0;
  z-index: 9;
  background: #000000;
  .trigger{
    width: 40px;
    min-width: 40px;
    height: 40px;
    object-fit: contain;
    background-color: $white;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      padding: 1px;
      width: 100%;
      height: 100%;
      z-index: -1;
      border-radius: 6px;
      background: linear-gradient(84.58deg, $buttonColor 8.66%, rgba($buttonColor, 0.4) 91.34%);
    }
    path{
      stroke: $buttonColor;
    }
  }
}
.search{
  width: calc( 100% - 50px);
  display: flex;
  height: 40px;
  border-radius: 6px;
  position: relative;
  background: #ffffff;
  input{
    height: 100%;
    width: 100%;
    border: none;
    padding: 0;
    margin: 0 50px 0 10px;
  }
  svg{
    position: absolute;
    top: 0;
    right: 10px;
    height: 100%;
    object-fit: contain;
  }
  &:before{
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    padding: 1px;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 6px;
    background: linear-gradient(84.58deg, $buttonColor 8.66%, rgba($buttonColor, 0.4) 91.34%);
  }
}
.overlay{
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
}
.wrapper{
  display: flex;
  align-items: center;
  height: 100vh;
  position: fixed;
  top: 0;
  right: -100%;
  z-index: 12;
  transition: all 0.3s;
  &.show{
    right: 0;
  }
}
.swipeForm{
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.swipe{
  width: 6px;
  height: 64px;
  background: $white;
  border-radius: 28px;
  margin-right: 15px;
}
.form{
  width: 280px;
  height: 100%;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 16px 0px 0px 16px;
  background-color: $white;
  transition: all 0.3s;
  position: relative;
}
.container{
  padding: 24px 16px;
}
.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}
.title{
  color: $black;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  svg{
    margin-right: 10px;
    path{
      stroke: $buttonColor;
    }
  }
}

.checkbox{
  position: absolute;
  z-index: -1;
  opacity: 0;
  &+label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    color: $black;
    font-size: 14px;
    font-weight: 600;
    &::before {
      content: '';
      display: inline-block;
      width: 1em;
      height: 1em;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid #adb5bd;
      border-radius: 0.25em;
      margin-right: 0.5em;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 55% 55%;
    }
  }
  &:checked{
    &+label {
      &::before {
        border-color: $buttonColor;
        background-color: $buttonColor;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 9'%3e%3cpath fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' d='M10.6666 1.5L4.24998 7.91667L1.33331 5'/%3e%3c/svg%3e");
      }
    }
  }
}
.button{
  margin-top: 32px;
  text-decoration: none;
  background: $buttonColor;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  &+&{
    margin-top: 16px;
    background: $black;
  }
}
